import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';

import { BorderRadius, TitleColour } from '../../utils/variables';

import './style.css';

import loadComponents from '../Loadable';

const H3 = loadComponents('h3');
const Button = loadComponents('button');

export default function Cta(props) {
  const { data } = props;
  return (
    <div
      className="subpageComponent ctaOuter"
      style={{
        backgroundColor: TitleColour,
        padding: `3.75rem`,
        borderRadius: BorderRadius,
      }}
    >
      <Row align="center" justify="space-between">
        <Col xl={{ width: '65%' }}>
          <H3 size="26px" margin="1.875rem" colour="#fff">
            {data.title}
          </H3>
          {data.content && <p className="content">{data.content}</p>}
        </Col>
        <Col className="button" lg={{ width: 'auto' }}>
          <Button to={data.buttonLink} primary style={buttonStyle}>
            {data.buttonText}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

const buttonStyle = {
  borderRadius: `100px`,
  padding: `20px 45px`,
  fontSize: `22px`,
};
